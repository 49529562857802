





















































import Vue from "vue";
export default Vue.component("QNotification", {
  props: {
    parent: {
      type: undefined, // HTMLElement
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: false,
      default: 'alert',
    }
  },
  mounted() {
    (this.parent as HTMLElement).appendChild(this.$el);
    setTimeout(() => {
      this.close();
    }, 3000);
  },
  computed: {
    statusClass() {
      return `brighttheme-${this.status}`;
    },
  },
  methods: {
    handleClick() {
      setTimeout(() => {
        this.close();
      }, 100);
    },
    close() {
      this.$emit("close");
      this.destroyed();
    },
    destroyed() {
      this.$el.remove();
    },
  },
});
